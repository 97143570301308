import * as React from "react"
import Layout from "../components/layout"
import { Box, Text, Flex, Container,
  Button,
  Center } from "@chakra-ui/react"
import { Link as GatsbyLink, graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import { motion } from 'framer-motion'

import Seo from "../components/seo"
import HomeBackground from "../components/HomePage/HomeBackround"
import PurpleSectionBackground from "../components/HomePage/Grizzly399Project/PurpleSectionBackround"
import HomepageContent from "../components/HomePage/Grizzly399Project/Grizzly399ProjectContent"
import FriendsOf399Section from "../components/HomePage/FriendsOf399/FriendsOf399Section"
import SliceZone from "../components/SliceZone/SliceZone"
import { VscTriangleDown } from "react-icons/vsc"


const MotionBox = motion(Box)

const IndexPage = ({ data, location }) => {
  const pageLocation = location.pathname;

  if (!data) return null; 

  const document = data.prismicHomepage.data
  const backgroundImage = getImage(document.hero_image.localFile);
  const image = getImage(backgroundImage);
  const mobileBackgroundImage = getImage(document.mobile_image.localFile);
  const mobileImage = getImage(mobileBackgroundImage);

  console.log(document)

  return (
  
<Layout location={pageLocation}>
  <Seo title="Bear 399 - A Campaign for Wildlife Education in the Tetons." />
      <HomeBackground 
        heroImage={image}
        mobileImage={mobileImage}
      />

      <Flex flexDirection="column" 
          className="flex-me"
          justifyContent={{base: 'flex-start', md: 'center'}}
          position="absolute"
          top={{base: "5.5rem", md: "2.5rem"}}
          height="100%"
          w="100%">
      <Box>
      <Flex
          h="100%"
          alignContent="center"
          alignItems="center"
          w="100%"
          
        >
        <Container maxW="container.md" centerContent pb="8">
        <MotionBox
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: 1,
            delay: 0.75,
          }}
        >
            <Text as="h1" className="hero"
              fontSize={{ sm: '3rem', md: '4rem' }}
              lineHeight={{ sm: '3rem', md: '4rem'}}
              px="4"
             >
               {document.hero_headline.text}
              </Text>
             <Container maxW="container.sm">
        <Flex justifyContent="center">
        
          {document.hero_buttons.map((button, index) => (
            <Box m={8}>
              <Center>
                <Button fontFamily="Open Sans" 
                  colorScheme="gray"
                  backgroundColor="#343434"
                  color="white"
                  textTransform="uppercase"
                  as={GatsbyLink}
                  to={button.button_link.url}
                  _hover={{ backgroundColor: "#454545" }}
                  _active={{ backgroundColor: "#454545" }}
                  >
                    {button.button_label.text}
                  </Button>
              </Center>
                </Box>
          ))}
                </Flex>
                </Container>
                </MotionBox>
          </Container>
        </Flex>
        </Box>
        
          <Box position="absolute"
              bottom={{base: "30px", md: '0px'}}
              marginBottom="1rem"
            >
            <Text textTransform='uppercase'
              p="4"
              color="white"
              fontWeight="700"
              fontFamily="Open Sans"
              fontSize=".75rem"
            >Photo: {document.hero_image.copyright}</Text>
          </Box>
        </Flex> 
        <Box textAlign="center" position="absolute" bottom="0" left={{base: "40%", md: "47.5%"}}>
            <Text fontFamily='Open Sans' fontWeight="600" color="white" mb="0">
              SCROLL
              </Text>
            <Box><Center><VscTriangleDown color="white" size="40" style={{textAlign: 'center'}} /></Center></Box>
          </Box>
      <Box w="100%">
        
        <PurpleSectionBackground>
        
        <HomepageContent 
          title={document.body[0].primary.title.text}
          text={document.body[0].primary.content.richText}
        />
        </PurpleSectionBackground>
      </Box>
      
      <Box w="100%">
         <FriendsOf399Section 
          friendsText={document.body[1].primary.friends_of_399.document.data.friends_text.richText}
          friendsTitle={document.body[1].primary.friends_of_399.document.data.friends_title.text}
          link={document.body[1].primary.friends_of_399.document.data.link.url}
          linkLabel={document.body[1].primary.friends_of_399.document.data.link_label.text}
        />
        <SliceZone sliceZone={document.body} />
      </Box>
  </Layout>
  )
}

export const query = graphql`
{
  prismicHomepage {
    data {
      hero_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        alt
        copyright
      }
      mobile_image {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        alt
        copyright
      }
      hero_headline {
        text
      }
      hero_buttons {
        button_link {
          url
        }
        button_label {
          text
        }
      }
      body {
        ... on PrismicHomepageDataBodyFriends {
          id
          slice_type
          primary {
            friends_of_399 {
              document {
                ... on PrismicFriendsOf399 {
                  id
                  type
                  data {
                    friend {
                      friend_title {
                        text
                      }
                      friend_name {
                        text
                      }
                      friend_image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1
                              layout: FULL_WIDTH
                              placeholder: BLURRED
                              transformOptions: {cropFocus: CENTER}
                            )
                          }
                        }
                        alt
                      }
                      friend_organization {
                        text
                      }
                      bio {
                        richText
                      }
                      image_5 {
                        alt
                        copyright
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1
                              layout: FULL_WIDTH
                              placeholder: BLURRED
                              transformOptions: {cropFocus: CENTER}
                            )
                          }
                        }
                      }
                      image_4 {
                        alt
                        copyright
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1
                              layout: FULL_WIDTH
                              placeholder: BLURRED
                              transformOptions: {cropFocus: CENTER}
                            )
                          }
                        }
                      }
                      image_3 {
                        alt
                        copyright
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1
                              layout: FULL_WIDTH
                              placeholder: BLURRED
                              transformOptions: {cropFocus: CENTER}
                            )
                          }
                        }
                      }
                      image_2 {
                        alt
                        copyright
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1
                              layout: FULL_WIDTH
                              placeholder: BLURRED
                              transformOptions: {cropFocus: CENTER}
                            )
                          }
                        }
                      }
                      image_1 {
                        alt
                        copyright
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              aspectRatio: 1
                              layout: FULL_WIDTH
                              placeholder: BLURRED
                              transformOptions: {cropFocus: CENTER}
                            )
                          }
                        }
                      }
                    }
                    friends_text {
                      richText
                    }
                    friends_title {
                      text
                    }
                    link {
                      url
                      target
                    }
                    link_label {
                      text
                    }
                  }
                }
              }
            }
          }
        }
        ... on PrismicHomepageDataBodyGrizzly399ProjectBlock {
          id
          slice_type
          primary {
            title {
              text
            }
            content {
              richText
              text
            }
          }
        }
      }
    }
  }
}
`

export default IndexPage
