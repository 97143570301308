import React from 'react'
import { Box,
        Text,
        Heading,
        Container,
} from '@chakra-ui/react'
import FriendsOf399Background from './FriendsOf399Background'
import { RichText } from 'prismic-reactjs'

const FriendsOf399Section = (props) => (
    <>
    <FriendsOf399Background>
    <Box id="" my="0">
    <Container maxW="container.md" py={28} centerContent>
        <Heading as="h1" color="#3a2152"
              fontSize={{ sm: '3rem', md: '4rem' }}
              lineHeight={{ sm: '3rem', md: '4rem'}} 
              my={12}
              textAlign="center">
                  {props.friendsTitle}
            </Heading>
        
    <Text
        color="#3a2152"
        fontSize="2rem"
        textAlign="center"
        mb={12}
        maxW="container.md"
        fontWeight="400"
        className='friends-text'
        >
            
            <RichText render={props.friendsText} />
    </Text>
    {/* <GatsbyLink to="/">
        <Button
            fontFamily="Open Sans"
            fontWeight="600"
            color="#3a2152"
            fontSize="1.25rem"
            textTransform="uppercase"
            background="none"
            _hover={{ color: 'gold.400'}}
        >
            {props.linkLabel}&nbsp;<ImArrowRight />
        </Button>
    </GatsbyLink> */}

            </Container>
    
    </Box>
    </FriendsOf399Background>
    {/* <Box w="100%">
        <FriendsImageGrid />
    </Box> */}
    </>
)

export default FriendsOf399Section