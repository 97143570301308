

import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { Text, Box } from '@chakra-ui/react'


const Logo = styled.svg `
  width: ${props => props.width ? props.width : '200px' };
  height: auto;
  margin-left: auto;
    margin-right: auto;
  .cls-1 {
    fill: #fff;

      }

`;

const GradCapIcon = props => {
    return (
  <Link to="/deidre-bainbridge-wildlife-fund" className="icon-link">
    <Box mb={3}>
<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 196.53 124.33738" {...props}>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="M196.53,104.10892h-13.9c8.9-21.09,3.35-42.76,5.39-64.92-2.68,1.01-4.5,1.63-6.26,2.37q-35.415,14.85-70.8,29.76a33.63468,33.63468,0,0,1-26.21.48q-39.54-15.225-79.1-30.4a4.89956,4.89956,0,0,1-1.39-.53c-1.45-1.24-2.84-2.56-4.26-3.85,1.33-1.34,2.49-2.96,4.04-3.96a28.64057,28.64057,0,0,1,5.53-2.26q37.59-14.085,75.18-28.17c8.25-3.11,16.53-3.62,24.89-.67q39.255,13.875,78.48,27.84c3.55,1.26,7.39,2.49,3.86,7.61-.64.92-.21,2.6-.21,3.93-.01,14.49-.06,28.99.05,43.48a31.0315,31.0315,0,0,0,1.16,7.32C193.97,95.98892,195.23,99.75891,196.53,104.10892Z"/>
        <path class="cls-1" d="M33.82,67.90894c8.38.16,16.18-1.06,23.91,2.47,8.45,3.85,17.32,6.8,26.03,10.07,10,3.75,19.84,3.25,29.61-1.02,8.23-3.59,16.31-7.79,24.89-10.21,6.54-1.85,13.75-1.35,21.77-1.99-.4,8.91-.25,17.03-1.28,25-.71,5.48-4.48,9.63-9.69,11.91-14.02,6.12-28.03,12.27-42.16,18.13-7.23,3-14.71,2.63-21.89-.42-12.86-5.46-25.61-11.15-38.45-16.64-8.65-3.7-12.77-10.22-12.74-19.53C33.83,80.00891,33.82,74.34894,33.82,67.90894Z"/>
      </g>
    </g>
  </g>
</Logo>
</Box>
<Text 
  textAlign="center"
  fontFamily="Open Sans"
  color="white"
    >Learn About the Deidre<br />Bainbridge Wildlife Fund</Text>
</Link>
    )
}


export default GradCapIcon
