import React from 'react'
import {
  Box
} from '@chakra-ui/react'
import { BgImage } from 'gbimage-bridge'
import styled from '@emotion/styled'


const BackgroundSection = (props) => {
  const { className, children } = props

  return (
    <>
    <Box style={{width: '100%', height: '100vh'}} display={{base: 'none', md: 'block'}}>
      <BgImage
          tag="section"
          className={className}
          image={props.heroImage}
      >
          {children}
      </BgImage>
    </Box>
    <Box style={{width: '100%', height: '100vh'}} display={{base: 'block', md: 'none'}}>
    <BgImage
        tag="section"
        className={className}
        image={props.mobileImage}
    >
        {children}
    </BgImage>
  </Box>
  </>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
    width: 100%;
    min-height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: ${props => props.theme.breakpoints.md}) {
      background-size: contain;
      background-position: center center;
    }
`;

export default StyledBackgroundSection