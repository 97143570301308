import React from 'react'
import { Box,
        Text,
        Heading,
        Container,
        Flex,
        Center
        } from '@chakra-ui/react'
import BearIcon from "./BearIcon"
import GradCapIcon from './GradCapIcon'
import { RichText } from 'prismic-reactjs'
import { VscTriangleDown } from "react-icons/vsc"

const HomePageContent = (props) => (
    <>
    <Box id="green-building" my="0">
    <Container maxW="container.md" py={28} centerContent>
        <Heading as="h1" className="hero"
              fontSize={{ sm: '3rem', md: '4rem' }}
              lineHeight={{ sm: '3rem', md: '4rem'}} 
              my={12}
              textAlign="center">
                  {props.title}
            </Heading>
        
    <Text color="white"
        fontSize="1.5rem"
        textAlign="center"
        mb={12}
        maxW="container.md"
        className='white-block-text'
        >
            <RichText render={props.text} />
    </Text>

    <Container maxW="container.sm"
        my={20}>
            <Flex justifyContent="space-around" flexWrap="wrap">
            <Box mb={6}>
                <BearIcon width="6rem" />
            </Box>
            <Box mb={6}>
                <GradCapIcon width="6rem" />
            </Box>
            </Flex>
    </Container>
    <Box textAlign="center" position="absolute" bottom="0" left="47.5%">
            <Text fontFamily='Open Sans' fontWeight="600" color="white" mb="0">
              SCROLL
              </Text>
            <Box><Center><VscTriangleDown color="white" size="40" style={{textAlign: 'center'}} /></Center></Box>
          </Box>
            </Container>
    
    </Box>
    </>
)

export default HomePageContent